// import dependencies
import React from "react";

// import components
import Layout from "../components/layout";
import Section from "../components/section";

// import styles and assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/font-awesome";
import portrait from "../images/bottle.png";
import * as styles from "./styles/info.module.scss";

export default function InfoPage() {
    return (
        <Layout>
            <Section>
                <div className={styles.header}>
                    <img src={portrait} alt="Tonico logo" />
                    <h1>Tónico blog</h1>
                </div>
                <div className={styles.mainContent}>
                    <p>
                        Hi, emmm hello, I'm Pablo, a designer and developer from
                        Chile, I work on videogames with a small team called
                        Tonico and this is the Tonico blog, yeah I known what a
                        surprise. I intend to write about games that I enjoyed,
                        some games that I didn't enjoy that much and other games
                        related stuff. This is my attempt to entertaining myself
                        with writting, a thing that I like very much, but also,
                        it's a way for me to learn more about games, I want to
                        learn more about mechanics, programming, design,
                        writting, art, etc. Games are an art form that results
                        incredibly fun to dissect, these will be, most of the
                        times, my personal opinions and feelings about this game
                        related stuff. If you end up reading any of my thoughts
                        about games then I hope that you will enjoy thinking
                        about games too, just like I do.
                    </p>
                    <p>
                        If for some reason you want to get in touch, you can
                        always send me a message on any of those social stuff
                        some of you like so much, I'll try to answer but it will
                        probably take me some time. Also, don't forget to be
                        nice, I'll try my best to be nice too.
                    </p>
                    <p>
                        <a rel="me" href="https://peoplemaking.games/@pablov">
                            Mastodon{" "}
                            <FontAwesomeIcon icon={["fab", "mastodon"]} />
                        </a>{" "}
                        -{" "}
                        <a href="https://cohost.org/porotosGranados">
                            Cohost <FontAwesomeIcon icon={["fas", "link"]} />
                        </a>
                    </p>
                </div>
            </Section>
        </Layout>
    );
}
